import i18n from '@/application/i18n/i18n'
import { betaGuard } from '@/application/routes/guards/beta-guard'
import { SETTINGS_PRODUCTS_ROUTE } from '@/application/routes/settings.routes.names'
import { SHIPPING_INCOMING_ORDERS_ROUTE } from '@/features/incoming-orders/routes'
import { PRODUCTS_BETA_FEATURE } from '@/features/products/beta-features'
import type { RouteRecordRaw } from 'vue-router'

export const SETTINGS_PRODUCTS_WIZARD_INTRO_ROUTE = 'products.wizard.intro'
export const SETTINGS_PRODUCTS_WIZARD_UPLOAD_ROUTE = 'products.wizard.upload'
export const SETTINGS_PRODUCTS_UPLOAD_ROUTE = 'products.upload'

export default [
  {
    name: SETTINGS_PRODUCTS_ROUTE,
    path: '/settings/products',
    component: () => import('@/features/products/pages/ProductsPage.vue'),
    beforeEnter: betaGuard(PRODUCTS_BETA_FEATURE, SHIPPING_INCOMING_ORDERS_ROUTE),
    meta: {
      pageTitle: () => i18n.t('settings.products.page_title'),
      permissions: ['manage_products'],
      parent: 'settings-products',
    },
  },
  {
    name: SETTINGS_PRODUCTS_WIZARD_INTRO_ROUTE,
    path: '/settings/products/wizard/intro',
    component: () => import('@/features/products/pages/ProductsWizardIntroPage.vue'),
    meta: {
      pageTitle: () => i18n.t('settings.products.upload_csv_page_title'),
      permissions: ['manage_products'],
      backLinkRoute() {
        return {
          title: i18n.t('Go back'),
          to: { name: SETTINGS_PRODUCTS_ROUTE },
        }
      },
      parent: 'settings-products',
    },
  },
  {
    name: SETTINGS_PRODUCTS_WIZARD_UPLOAD_ROUTE,
    path: '/settings/products/wizard/upload',
    component: () => import('@/features/products/pages/ProductsWizardUploadCSVPage.vue'),
    meta: {
      pageTitle: () => i18n.t('settings.products.upload_csv_page_title'),
      permissions: ['manage_products'],
      backLinkRoute() {
        return {
          title: i18n.t('Go back'),
          to: { name: SETTINGS_PRODUCTS_WIZARD_INTRO_ROUTE },
        }
      },
      parent: 'settings-products',
    },
  },
  {
    name: SETTINGS_PRODUCTS_UPLOAD_ROUTE,
    path: '/settings/products/upload',
    component: () => import('@/features/products/pages/ProductsUploadCSVPage.vue'),
    meta: {
      pageTitle: () => i18n.t('settings.products.upload_csv_page_title'),
      permissions: ['manage_products'],
      backLinkRoute() {
        return {
          title: i18n.t('Go back'),
          to: { name: SETTINGS_PRODUCTS_ROUTE },
        }
      },
      parent: 'settings-products',
    },
  },
] satisfies RouteRecordRaw[]
