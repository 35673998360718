import axios from '@/common/utils/axios'
import i18n from '@/application/i18n/i18n'
import { normalizeError } from '@/common/utils/errors/normalize-error'

import type { User } from '@/types/models'
import type { AgentLoginPermission, UnsavedAgentLoginPermission } from '@/features/account/types/account.types'
import type { ExportedUserData } from '@/features/account/types/user.types'

export default {
  async getMe(): Promise<User> {
    const { data } = await axios.get('/xhr/users/me')
    return data
  },

  async update(payload: Partial<User>): Promise<User> {
    const { data } = await axios.patch('/xhr/users/me', payload)
    return data
  },

  async delete(): Promise<User> {
    const { data } = await axios.delete('/xhr/users/me')
    return data
  },

  async unfreeze(): Promise<User> {
    const { data } = await axios.post('/xhr/users/me/unfreeze')
    return data
  },

  async acceptedTermsAndConditions() {
    const { data } = await axios.post('/xhr/users/me/accept_terms_conditions', {})
    return data
  },

  async exportUserData(): Promise<ExportedUserData> {
    const { data } = await axios.post('/xhr/users/export_user_data')
    return data
  },

  async getUserData(): Promise<ExportedUserData> {
    const { data } = await axios.get('/xhr/users/exported_user_data')
    return data
  },

  async stopAgentLoginSession(): Promise<void> {
    const response = await axios({
      method: 'post',
      url: '/hijack/release/',
    })
    return response.data
  },

  /**
   * Retrieves the granted agent login permission for the currently active user.
   */
  async getAgentLoginPermission(): Promise<AgentLoginPermission> {
    try {
      const response = await axios.get('/xhr/users/agent-login-permission')
      return response.data
    } catch (error) {
      throw normalizeError(error, i18n.t('Could not load agent login permission data.'))
    }
  },

  /**
   * Grants an agent login permission for a limited amount of time.
   */
  async grantAgentLoginPermission(agentLoginPermission: UnsavedAgentLoginPermission): Promise<AgentLoginPermission> {
    try {
      const response = await axios.post('/xhr/users/agent-login-permission', agentLoginPermission)
      return response.data
    } catch (error) {
      throw normalizeError(error, i18n.t('Could not grant agent login permission data.'))
    }
  },

  /**
   * Revokes an agent login permission.
   */
  async revokeAgentLoginPermission(): Promise<AgentLoginPermission> {
    try {
      const response = await axios.delete('/xhr/users/agent-login-permission')
      return response.data
    } catch (error) {
      throw normalizeError(error, i18n.t('Could not revoke agent login permission data.'))
    }
  },
}
