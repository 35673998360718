import i18n from '@/application/i18n/i18n'
import type { RouteRecordRaw } from 'vue-router'

export const FEDEX_ADDRESS_VALIDATION = 'contracts.fedex.address_validation'
export const FEDEX_SELECT_VALIDATION_METHOD = 'contracts.fedex.select.mfa'
export const FEDEX_PIN_VALIDATION = 'contracts.fedex.pin_validation'
export const FEDEX_INVOICE_VALIDATION = 'contracts.fedex.invoice_validation'

const fedexRoutes: RouteRecordRaw[] = [
  {
    name: FEDEX_ADDRESS_VALIDATION,
    path: 'contracts/carrier/:carrierId/register/:carrierCode?',
    component: () => import('@/features/carriers/pages/fedex-mfa/FedexAddressValidation.vue'),
    props: route => ({
      carrierId: Number.parseInt(route.params.carrierId as string),
    }),
    meta: {
      pageTitle: () => i18n.t('carriers.fedex-mfa.page.address_validation'),
      permissions: ['configure_shipping'],
    },
  },
  {
    name: FEDEX_SELECT_VALIDATION_METHOD,
    path: 'contracts/carrier/:carrierId/register/:carrierCode?',
    component: () => import('@/features/carriers/pages/fedex-mfa/FedexSelectValidationMethod.vue'),
    props: route => ({
      carrierId: Number.parseInt(route.params.carrierId as string),
    }),
    meta: {
      pageTitle: () => i18n.t('carriers.fedex-mfa.page.select_validation_method'),
      permissions: ['configure_shipping'],
    },
  },

  {
    name: FEDEX_PIN_VALIDATION,
    path: 'contracts/carrier/:carrierId/register/:carrierCode?',
    component: () => import('@/features/carriers/pages/fedex-mfa/FedexPinValidation.vue'),
    props: route => ({
      carrierId: Number.parseInt(route.params.carrierId as string),
    }),
    meta: {
      pageTitle: () => i18n.t('carriers.fedex-mfa.page.pin_validation'),
      permissions: ['configure_shipping'],
    },
  },
  {
    name: FEDEX_INVOICE_VALIDATION,
    path: 'contracts/carrier/:carrierId/register/:carrierCode?',
    component: () => import('@/features/carriers/pages/fedex-mfa/FedexInvoiceValidation.vue'),
    props: route => ({
      carrierId: Number.parseInt(route.params.carrierId as string),
    }),
    meta: {
      pageTitle: () => i18n.t('carriers.fedex-mfa.page.invoice_validation'),
      permissions: ['configure_shipping'],
    },
  },
]

export default fedexRoutes
