export const CARRIER_PAGE_REORDER_EXPERIMENT_ID = 'italian_contract_page_ordering'
export const AMAZON_STATE_TOKEN = 'SendCloud_Authoirzation'
export const UPS_CLIENT_ID = 'UGGV56FqEOp3S4kVyBGll7loaOCtWaLoT8rtq3kxJ7q2Gm1i'
export const UPS_SSO_URL = 'https://www.ups.com/lasso/signin'
export const HERMES_SC_CLIENT_ID = 'hsi.verm.sendcloud'
export const BOL_SC_CLIENT_ID = '4048b124-a074-48a6-b094-c2365c2b8fde'

export const CARRIERS_ID_SENDCLOUD = 7
export const CARRIERS_ID_UPS = 8
export const CARRIERS_ID_CORREOS = 24
export const CARRIERS_ID_CORREOS_EXPRESS = 26
export const CARRIERS_ID_HERMES_GB = 34
export const CARRIERS_ID_FEDEX = 47
export const CARRIERS_ID_HERMES_C2C_GB = 52
export const CARRIERS_ID_FEDEXCROSSBOARDER = 56
export const CARRIERS_ID_PARCELFORCE = 58
export const CARRIERS_ID_CTT_EXPRESS = 62

/**
 * This is going to be included in the API but for now we rely on a hardcoded list of carriers with the Zonal Pricing.
 *
 * @see [Upload Own Prices Documentation]{@link https://support.sendcloud.com/hc/en-us/
 * articles/5163547066004-How-to-upload-your-own-prices-from-your-direct-carrier-contract}
 */
export const CARRIERS_WITH_ZONAL_PRICING: number[] = [
  CARRIERS_ID_SENDCLOUD,
  CARRIERS_ID_CORREOS,
  CARRIERS_ID_CORREOS_EXPRESS,
  CARRIERS_ID_HERMES_GB,
  CARRIERS_ID_FEDEX,
  CARRIERS_ID_HERMES_C2C_GB,
  CARRIERS_ID_FEDEXCROSSBOARDER,
  CARRIERS_ID_PARCELFORCE,
  CARRIERS_ID_CTT_EXPRESS,
]

export const COUNTRY_CODE_SPAIN = 'ES'

export const DATA_URI_PREFIX = 'data:image/gif;base64,'
export const CARRIER_ID_DHL_DE = 6
/**
 * List of the carrier IDs to provide sync validation before update the contract credentials.
 */
export const CARRIER_IDS_WITH_SYNC_VALIDATION = [CARRIER_ID_DHL_DE]

export const FEDEX_VALIDATION_METHODS = {
  SMS: 'SMS',
  EMAIL: 'EMAIL',
  CALL: 'CALL',
  INVOICE: 'INVOICE',
}
