import { uniqueBy } from '@/common/utils/array-helpers/unique-by'

import axios, { cancelTokenFactory } from '@/common/utils/axios'
import i18n from '@/application/i18n/i18n'
import { SHIPPING_FETCH_SHIPPING_METHODS_LAZILY } from '@/features/shipment-tabs/stores/shipping/action.types.js'
import store from '@/common/stores/store'
import { getVariable, setVariable, LOCAL_STORAGE_KEYS } from '@/common/utils/storage'
import ModalService from '@/common/services/modal.service'
import { DIRECTION_OUTGOING, LABEL_PRICE_BREAKDOWN_TYPE, LETTER_SHIPPING_METHOD_ID } from '@/app/common/constants'
import Segment from '@/common/utils/tracking/segment'

import type { AxiosRequestConfig, CancelTokenSource } from 'axios'
import type {
  AnnouncedParcel,
  BatchShipmentPayload,
  CancelShipmentPayload,
  ForceDeliveryShipmentResponsePayload,
  QueryParams,
  Parcel,
  ParcelRequisites,
  ParcelServiceRequisitesArguments,
  ShipmentBlobParams,
  MonitorableParcel,
} from '@/types/shipping.models'
import type { RawAddressType, SenderAddress } from '@/features/addresses/types/addresses.types'
import type { ParcelRequisitesParameters } from '@/types/api'
import type { Order } from '@/types/orders.models'
import type { ImporterServiceFilterDropdown, ImporterServiceFilterDateRange } from '@/types/services'

let lastRequestSource: CancelTokenSource

function updateRequestQueue() {
  // create and return a new cancellation token after cancelling the previous one
  if (lastRequestSource) {
    lastRequestSource.cancel('Request was cancelled because a new request was made while it was pending')
  }
  lastRequestSource = cancelTokenFactory()

  return lastRequestSource
}

function hasPrintableCustomsForm(parcel: Partial<Parcel>): boolean {
  return (
    parcel.parcel_labels?.some(label => label.type.includes('cn23') || label.type.includes('commercial-invoice')) ??
    false
  )
}

export default {
  /**
   *
   * @param id
   * @param addressType determines the format in which the to_address object will be returned. Split contains
   * a house number in the house_number field of the to_address, while combined will contain the house number
   * in the address_line_1 meaning that address_line_1 represents a full address.
   *
   */
  find(id: number, addressType?: RawAddressType): Promise<Parcel> {
    const params: Record<string, unknown> = {}
    if (addressType) {
      params.to_address_type = addressType
    }
    return axios.get(`/xhr/parcel/${id}`, { params: params }).then(response => response.data)
  },

  findAll(
    // I added ids separately, as I have not context whether its okay to include it into QueryParams
    params: URLSearchParams | QueryParams | { ids: string },
    requestOptions = { allowConcurrentRequests: false },
  ): Promise<{
      filters: ImporterServiceFilterDropdown & ImporterServiceFilterDateRange
      next: string
      ordering: []
      results: Parcel[]
    }> {
    const options: AxiosRequestConfig = params ? { params } : {}
    if (!requestOptions || !requestOptions.allowConcurrentRequests) {
      updateRequestQueue()
      options.cancelToken = lastRequestSource.token
    }
    return axios.get('/xhr/parcel', options).then(response => response.data)
  },

  update(id: number, data: Parcel): Promise<Parcel> {
    return axios.put(`/xhr/parcel/${id}`, data).then(response => response.data)
  },

  async bulkCancel(idList: number[]): Promise<CancelShipmentPayload> {
    const response = await axios.post('/xhr/parcel/cancel', {}, { params: { ids: idList.join(',') } })
    const summary = response.data.summary
    let message: string
    if (summary.processed.length === 0) {
      message = i18n.t('These shipments cannot be cancelled.')
      if (summary.failed.length > 0) {
        summary.failed.forEach((value: number[]) => {
          message += `\n${i18n.t('Shipment')} ${value[1]} - ${value[2]}`
        })
      }
    } else if (summary.queued.length > 0) {
      // At least one parcel got queued, so let's just display
      // a generic message static that "we are working on it" in the
      // background.
      message = i18n.t(
        'We are processing your cancellation request(s), you can follow up on the progress via the shipment status.',
      )
    } else if (summary.failed.length === 0) {
      // Nothing failed, nothing queued, so must be all good...
      message = i18n.t('The shipments were cancelled.')
    } else {
      message = i18n.t('The shipments were partly cancelled.')
    }
    response.data.message = message
    return response.data
  },

  bulkForceDelivery(idList: number[]): Promise<{ data: ForceDeliveryShipmentResponsePayload }> {
    return axios.post(
      '/xhr/parcel/force_delivery',
      {},
      {
        params: {
          ids: idList.join(','),
        },
      },
    )
  },

  createFromBlobs(
    orders: Order[],
    // could possibly accept undefined, but currently in code it is always sent
    senderAddress: SenderAddress['id'],
    params: ShipmentBlobParams,
    isAllowingReprocessing = false,
  ): Promise<MonitorableParcel[]> {
    const shipments = orders.map((order) => {
      const hasSenderAddressRule = order.rule_modifications.includes('sender_address')
      const finalSenderAddress = hasSenderAddressRule ? order.sender_address : senderAddress

      return {
        shipment_blob: order.shipment_blob,
        modifications: {
          sender_address: finalSenderAddress,
          total_insured_value: order.total_insured_value,
          shipping_method: order.shipping_method,
          to_post_number: order.to_post_number,
        },
        allow_reprocessing: isAllowingReprocessing,
      }
    })

    return axios.post('/orders/xhr/parcel/shipment-blob', shipments, { params }).then(response => response.data)
  },

  createFromRawData(
    payload: BatchShipmentPayload,
    senderAddress: Pick<SenderAddress, 'id'>,
  ): Promise<MonitorableParcel[]> {
    if (payload.shipment.direction === DIRECTION_OUTGOING) {
      payload.shipment.sender_address = senderAddress.id
    } else {
      payload.shipment.return_address = senderAddress.id
    }
    return axios.post('/xhr/parcel/batch', payload).then(response => response.data)
  },

  announce(parcels: AnnouncedParcel[]): Promise<Parcel[]> {
    return axios.post('/xhr/parcel/announce', parcels)
  },

  async showDeutschePostInternationalInfoMessage(shippingMethodIds: number[]): Promise<void> {
    await store.dispatch(SHIPPING_FETCH_SHIPPING_METHODS_LAZILY)

    return new Promise((resolve) => {
      const hasDeutschePostInternationalShippingMethod = shippingMethodIds.some((shippingMethodId) => {
        const shippingMethod = store.getters.shippingMethodById(shippingMethodId)

        if (shippingMethod !== undefined) {
          return shippingMethod.friendly_name.toLowerCase().includes('deutsche post international')
        } else {
          return false
        }
      })

      if (hasDeutschePostInternationalShippingMethod) {
        const doNotShowStorageKey = LOCAL_STORAGE_KEYS.DO_NOT_SHOW_DEUTSCHE_POST_INTERNATIONAL_MESSAGE
        const doNotShow = getVariable(store.getters.user.id, doNotShowStorageKey) || false

        if (doNotShow) {
          resolve()
        } else {
          const bodyMessage = i18n.t(
            'Some of the labels you’re creating use a DHL Global Mail shipping method. The necessary air waybill can be generated from the created labels view. For more information, please visit our <a href="https://support.sendcloud.com/hc/en-us/articles/360039523572">help center</a>.',
          )

          ModalService.build('DeutschePostInternationalModal', {
            id: 'deutsche-post-international-info',
            confirm: (options: { doNotShowModalAgain: boolean }) => {
              this._saveDoNotShowModalAgain(options)
              resolve()
            },
            showDoNotShowCheckbox: true,
            showCancelButton: false,
            title: i18n.t('DHL Global Mail'),
            bodyMessage,
          })
        }
      } else {
        resolve()
      }
    })
  },

  _saveDoNotShowModalAgain(options: { doNotShowModalAgain: boolean }): void {
    if ('doNotShowModalAgain' in options) {
      const doNotShowStorageKey = LOCAL_STORAGE_KEYS.DO_NOT_SHOW_DEUTSCHE_POST_INTERNATIONAL_MESSAGE
      setVariable(store.getters.user.id, doNotShowStorageKey, options.doNotShowModalAgain)
    }
  },

  showAccountOnHoldModal(): Promise<void> {
    Segment.track('Create Labels on Hold modal')

    return new Promise((resolve) => {
      ModalService.build('AccountOnHoldModal', {
        id: 'account-on-hold',
        confirm: () => {
          resolve()
        },
        title: i18n.t('You can’t create labels while on hold'),
        bodyMessage: i18n.t(
          'Your account is currently being reviewed by our credit control team and has been put on hold. While on hold, you will not be able to create labels or proceed with shipping. This is usually settled within a few hours during working days.',
        ),
      })
    })
  },

  generateAirWaybill(payload: { awb_copy_count: number }): Promise<void> {
    return axios.post('/xhr/parcel/announce-air-waybill', payload)
  },

  validateOrder(orderData: Order): Promise<Order> {
    return axios.post('/xhr/parcel/validate', orderData).then(response => response.data)
  },

  // TODO Investigate types Shipment vs Order and their usages
  // https://sendcloud.atlassian.net/browse/SC-60259
  getValidationPayloadFromShipment(shipment: Partial<Order>): Order {
    // Delete unneeded shipment properties for validation
    const validationPayload = { ...shipment }

    delete validationPayload.allowed_shipping_methods
    delete validationPayload.order_extra
    delete validationPayload.rule_modifications
    // @ts-expect-error Property 'selectedShippingMethod' does not exist on type `Order`
    delete validationPayload.selectedShippingMethod
    delete validationPayload.errors

    // validation endpoint only accepts a decimal value or null
    if (validationPayload?.total_order_value === '') {
      validationPayload.total_order_value = null
    }

    return validationPayload as Order
  },

  requisites({
    fromCountryIso2,
    fromPostalCode,
    shippingMethodId,
    toCountryIso2,
    toPostalCode,
    totalInsuredValue,
    isCashOnDelivery,
    toAddress1,
    totalOrderValue,
    direction,
    contract,
    addShippingInsurance,
    toServicePoint,
    weight,
    length,
    height,
    width,
    parcels,
  }: ParcelServiceRequisitesArguments): Promise<ParcelRequisites> {
    const requestData: ParcelRequisitesParameters = {
      from_country: fromCountryIso2,
      country: toCountryIso2,
      shipping_method: shippingMethodId,
      weight,
      total_insured_value: totalInsuredValue,
      from_postal_code: fromPostalCode,
      to_postal_code: toPostalCode,
      is_cash_on_delivery: isCashOnDelivery,
      total_order_value: totalOrderValue,
      to_address_1: toAddress1,
      direction,
      height,
      width,
      length,
      contract,
      add_shipping_insurance: addShippingInsurance,
      to_service_point: toServicePoint,
      parcels,
    }

    return this._tryCatchRequisites(requestData)
  },

  _tryCatchRequisites(requestData: ParcelRequisitesParameters): Promise<ParcelRequisites> {
    return axios
      .post('/xhr/parcel/requisites', requestData)
      .then((response): ParcelRequisites => response.data)
      .then((res) => {
        // No need to show zero prices in FE pricing breakdown
        if (res.price && res.price.breakdown) {
          // To be on a safe side: sometimes at local env - the `res.price` is empty.
          const isUnstampedLetter = res.shipping_method === LETTER_SHIPPING_METHOD_ID
          res.price.breakdown = res.price.breakdown.filter(({ type, value }) => {
            // BUT we should only remove 0 values for the label price if it is not an unstamped letter
            if (isUnstampedLetter && type === LABEL_PRICE_BREAKDOWN_TYPE.LABEL) {
              return true
            }
            return Number(value) !== 0
          })
        }

        return res
      })
      .catch((err) => {
        const retryCondition = Boolean(requestData.contract)
        if (retryCondition) {
          // No need to consider attempts logic for the retry policy (MAX_ATTEMPTS=X),
          // because for the 2nd attempt the contract ID is removed
          // (which means the retryCondition for the 2nd attempt will be always false).
          return this._tryCatchRequisites({ ...requestData, contract: undefined })
        }

        throw err
      })
  },

  extendParcelList(parcels: Parcel[], newParcels: Parcel[]): Parcel[] {
    const ret = parcels.concat(newParcels).flat(Infinity)
    // NOTE: if the user is working in multiple tabs, adding parcels in one tab
    // shifts parcels across page boundaries, resulting in dupe parcels if we
    // are not careful.. so let's do this:
    return uniqueBy(ret, parcel => parcel.id)
    // (end NOTE)
  },

  checkForPendingParcels(): Promise<{ has_pending_items: boolean }> {
    return axios.get('/xhr/parcel/get_pending_items').then(response => response.data.has_pending_items)
  },

  /**
   * Check if we should try to print customs documents for a parcel. Looks at the documents available for the
   * parcel, and whether the parcel uses paperless trade. In the case of paperless trade, we send the customs documents
   * digitally so they should not be printed automatically.
   */
  shouldPrintCustomsDocuments(parcel: Partial<Parcel>): boolean {
    return !!parcel.requires_export_doc && !parcel.paperless_trade && hasPrintableCustomsForm(parcel)
  },

  /**
   * Check if we should let the user download customs documents for a parcel - i.e., if there is a customs document
   * available for printing. In the case of paperless trade, the user may wish to download the customs documents for
   * their own records, hence the difference from the `shouldPrintCustomsDocuments` method.
   */
  shouldDownloadCustomsDocuments(parcel: Parcel): boolean {
    return parcel.requires_export_doc && hasPrintableCustomsForm(parcel)
  },

  exportAsCsv(parcelIds: number[]): Promise<void> {
    return axios.post('/xhr/parcel/export-csv', parcelIds)
  },

  async delete(parcelId: number): Promise<void> {
    const response = await axios.delete(`/xhr/parcel/${parcelId}`)
    return response.data
  },

  async forceDelete(parcelId: number): Promise<void> {
    const response = await axios.delete(`/xhr/parcel/${parcelId}?force_delete=1`)
    return response.data
  },
}
