import type { CustomerIOAnalytics } from '@/common/types/marketing-tools.types'

let customerIoAnalytics: CustomerIOAnalytics | null = null

export function initializeCustomerIo(userId: number, userLanguage: string, userCountryISO: string) {
  if (!window._cio) {
    window._cio = [] as unknown[]
  }

  const _cio = window._cio

  if (Array.isArray(_cio)) {
    const cioMethods = ['load', 'identify', 'sidentify', 'track', 'page', 'on', 'off']

    cioMethods.forEach((method) => {
      (_cio as any)[method] = function (...args: unknown[]) {
        _cio.push([method, ...args])
      }
    })
  }

  const scriptElement = document.createElement('script')
  scriptElement.async = true
  scriptElement.id = 'cio-tracker'
  scriptElement.setAttribute('data-site-id', 'e35d60ae8aa6cc853a60')
  scriptElement.setAttribute('data-use-array-params', 'true')
  scriptElement.setAttribute('data-use-in-app', 'true')
  scriptElement.src = 'https://assets.customer.io/assets/track-eu.js'

  const firstScript = document.getElementsByTagName('script')[0]
  if (firstScript && firstScript.parentNode) {
    firstScript.parentNode.insertBefore(scriptElement, firstScript)
  }

  scriptElement.onload = () => {
    if (!Array.isArray(window._cio)) {
      customerIoAnalytics = window._cio as CustomerIOAnalytics

      // Combine userLanguage and userCountryISO to create the marketing_language according CIO
      const languagePrefix = userLanguage.split('-')[0]
      const marketingLanguage = userCountryISO ? `${languagePrefix}-${userCountryISO}` : 'nl'

      customerIoAnalytics.identify?.({ id: userId.toString(), marketing_language: marketingLanguage.toLowerCase() })
    }
  }
}

export function customerIoPage() {
  if (customerIoAnalytics) {
    customerIoAnalytics.page?.()
  }
}
