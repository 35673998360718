import i18n from '@/application/i18n/i18n'
import type { RouteRecordRaw } from 'vue-router'
import { determinePathContractId } from '@/features/carriers/utils/utils'

import { SETTINGS_PRICES_LIST_ROUTE } from '@/features/rates/routes'
import FEDEX_ROUTES from '@/features/carriers/fedex.routes'

export const SETTINGS_CARRIERS_ROUTE = 'carriers'
export const SETTINGS_CARRIERS_LIST_ROUTE = 'carriers.list'
export const SETTINGS_CARRIERS_NEW_LIST_ROUTE = 'carriers-new.list'
export const SETTINGS_CARRIERS_PRICE_LIST_ROUTE = 'prices.list'
export const SETTINGS_CONTRACTS_LIST_ROUTE = 'contracts.list'
export const SETTINGS_CONTRACTS_SELECT_CARRIER_ROUTE = 'contracts.select_carrier'
export const SETTINGS_CONTRACTS_CONFIGURE_ROUTE = 'contracts.configure'
export const SETTINGS_CONTRACTS_REGISTER_ROUTE = 'contracts.register'
export const SETTINGS_CONTRACTS_REGISTER_SUBBROKER_ROUTE = 'contracts.register.subbroker'

export default {
  name: SETTINGS_CARRIERS_ROUTE,
  path: '/shipping/carriers',
  component: () => import('@/features/carriers/pages/CarrierNav.vue'),
  redirect: { name: SETTINGS_CARRIERS_LIST_ROUTE },
  children: [
    {
      name: SETTINGS_CARRIERS_LIST_ROUTE,
      path: 'list',
      component: () => import('@/features/carriers/pages/CarrierSelectionPage.vue'),
      meta: {
        pageTitle: () => i18n.t('Carriers'),
        permissions: ['configure_shipping'],
        parent: 'shipping-carriers',
      },
      alias: ['/settings/carriers/list', '/shipping/carriers/list'],
    },
    {
      name: SETTINGS_CARRIERS_NEW_LIST_ROUTE,
      path: 'list-new',
      component: () => import('@/features/carriers/pages/new-version/CarrierListPage.vue'),
      meta: {
        pageTitle: () => i18n.t('Carriers'),
        permissions: ['configure_shipping'],
        parent: 'shipping-carriers',
      },
      alias: ['/settings/carriers/list-new', '/shipping/carriers/list-new'],
    },
    {
      name: SETTINGS_CARRIERS_PRICE_LIST_ROUTE,
      path: 'prices/list',
      redirect: { name: SETTINGS_PRICES_LIST_ROUTE },
      meta: {
        pageTitle: () => i18n.t('aside.navigation.shipping_prices'),
        permissions: ['view_prices'],
        parent: 'shipping-carriers',
      },
    },
    {
      name: SETTINGS_CONTRACTS_LIST_ROUTE,
      path: 'contracts/list',
      component: () => import('@/features/carriers/pages/ContractsListPage.vue'),
      meta: {
        pageTitle: () => i18n.t('Carriers'),
        permissions: ['configure_shipping'],
        parent: 'shipping-carriers',
      },
      alias: ['/settings/carriers/contracts/list', '/shipping/carriers/contracts/list'],
    },
    {
      name: SETTINGS_CONTRACTS_SELECT_CARRIER_ROUTE,
      path: 'contracts/carrier/select',
      component: () => import('@/features/carriers/pages/CarrierContractsListPage.vue'),
      meta: {
        pageTitle: () => i18n.t('Carriers'),
        permissions: ['configure_shipping'],
        parent: 'shipping-carriers',
      },
      alias: ['/settings/carriers/contracts/carrier/select', '/shipping/carriers/contracts/carrier/select'],
    },
    {
      name: SETTINGS_CONTRACTS_CONFIGURE_ROUTE,
      path: 'contracts/carrier/:carrierId/configure/:contractId?',
      component: () => import('@/features/carriers/pages/CarrierContractFormPage.vue'),
      props: route => ({
        carrierId: Number.parseInt(route.params.carrierId as string),
        contractId: determinePathContractId(route.params.contractId),
      }),
      meta: {
        pageTitle: () => i18n.t('Carriers'),
        permissions: ['configure_shipping'],
        parent: 'shipping-carriers',
      },
      alias: ['/settings/carriers/contracts/carrier/:carrierId/configure/:contractId?', '/shipping/carriers/contracts/carrier/:carrierId/configure/:contractId?'],
    },
    {
      name: SETTINGS_CONTRACTS_REGISTER_SUBBROKER_ROUTE,
      path: 'contracts/carrier/:carrierCode/register-subbroker',
      component: () => import('@/features/carriers/pages/CarrierContractRegisterSubbrokerFormPage.vue'),
      props: route => ({
        carrierCode: route.params.carrierCode,
      }),
      meta: {
        pageTitle: () => i18n.t('Carriers'),
        permissions: ['configure_shipping'],
        parent: 'shipping-carriers',
      },
      alias: ['/settings/carriers/contracts/carrier/:carrierCode/register-subbroker', '/shipping/carriers/contracts/carrier/:carrierCode/register-subbroker'],
    },
    {
      name: SETTINGS_CONTRACTS_REGISTER_ROUTE,
      path: 'contracts/carrier/:carrierId/register/:carrierCode?',
      component: () => import('@/features/carriers/pages/CarrierContractRegisterFormPage.vue'),
      props: route => ({
        carrierId: Number.parseInt(route.params.carrierId as string),
        contractId: determinePathContractId(route.params.contractId),
        carrierCode: route.params.carrierCode,
      }),
      meta: {
        pageTitle: () => i18n.t('Carriers'),
        permissions: ['configure_shipping'],
        parent: 'shipping-carriers',
      },
      alias: ['/settings/carriers/contracts/carrier/:carrierId/register/:carrierCode?', '/shipping/carriers/contracts/carrier/:carrierId/register/:carrierCode?'],
    },
    ...FEDEX_ROUTES,
    {
      name: SETTINGS_CARRIERS_PRICE_LIST_ROUTE,
      path: 'prices/list',
      redirect: { name: SETTINGS_PRICES_LIST_ROUTE },
      meta: {
        pageTitle: () => i18n.t('aside.navigation.shipping_prices'),
        permissions: ['view_prices'],
        parent: 'shipping-carriers',
      },
    },
  ],
} satisfies RouteRecordRaw
