import type { Commit } from 'vuex'
import {
  SUPPORT_GO_TO_DRAFT_TICKET,
} from './action.types'
import {
  SUPPORT_ADD_REPLY_ATTACHMENTS,
  SUPPORT_SET_REPLY_ATTACHMENTS,
  SUPPORT_REMOVE_REPLY_ATTACHMENTS,
  SUPPORT_SET_DRAFT_TICKET,
} from './mutation.types'

type SupportState = {
  replyAttachments: string[]
  selectedDraftTicket: string | null
}

const state: SupportState = {
  replyAttachments: [],
  selectedDraftTicket: null,
}

const getters = {
  selectedDraftTicket: (state: SupportState) => state.selectedDraftTicket,
}

const actions = {
  [SUPPORT_GO_TO_DRAFT_TICKET]({ commit }: { commit: Commit }, selectedDraftTicket: string) {
    commit(SUPPORT_SET_DRAFT_TICKET, selectedDraftTicket)
  },
}

const mutations = {
  [SUPPORT_SET_REPLY_ATTACHMENTS](state: SupportState, selectedFiles: string[]) {
    state.replyAttachments = []
    selectedFiles.forEach((file: string) => state.replyAttachments.push(file))
  },
  [SUPPORT_ADD_REPLY_ATTACHMENTS](state: SupportState, selectedFiles: string[]) {
    [...selectedFiles].forEach((file: string) => state.replyAttachments.push(file))
  },
  [SUPPORT_REMOVE_REPLY_ATTACHMENTS](state: SupportState, key: number) {
    state.replyAttachments.splice(key, 1)
  },
  [SUPPORT_SET_DRAFT_TICKET](state: SupportState, selectedDraftTicket: string) {
    state.selectedDraftTicket = selectedDraftTicket
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
