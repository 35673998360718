import * as ReturnRulesService from '@/features/return-rules/api/return-rules.api'

import {
  RETURN_RULES_CREATE_RULE,
  RETURN_RULES_DELETE_RULE,
  RETURN_RULES_FETCH_RULE,
  RETURN_RULES_FETCH_RULES,
  RETURN_RULES_MOVE_RULES,
  RETURN_RULES_TOGGLE_RULE,
  RETURN_RULES_UPDATE_RULE,
} from '@/features/return-rules/stores/return-rules/action.types'

import { RETURN_RULES_SET_RETURN_RULE, RETURN_RULES_SET_RETURN_RULES } from '@/features/return-rules/stores/return-rules/mutation.types'

import type { Commit, Dispatch } from 'vuex'
import type { ReturnRule } from '@/types/rules.models'

type ReturnRulesState = {
  returnRules: ReturnRule[]
}

const state: ReturnRulesState = {
  returnRules: [],
}

const getters = {
  returnRules: (state: ReturnRulesState) => state.returnRules,
  returnRuleById: (state: ReturnRulesState) => (
    ruleId: number) => state.returnRules.find(returnRule => returnRule.id === ruleId,
  ),
}

const actions = {
  async [RETURN_RULES_CREATE_RULE]({ dispatch }: { dispatch: Dispatch }, returnRule: ReturnRule) {
    await ReturnRulesService.createReturnRule(returnRule)
    dispatch(RETURN_RULES_FETCH_RULES)
  },

  async [RETURN_RULES_DELETE_RULE]({ dispatch }: { dispatch: Dispatch }, returnRuleId: number) {
    await ReturnRulesService.deleteReturnRule(returnRuleId)
    dispatch(RETURN_RULES_FETCH_RULES)
  },

  async [RETURN_RULES_FETCH_RULE]({ commit }: { commit: Commit }, id: number) {
    try {
      const response = await ReturnRulesService.fetchReturnRule(id)
      const returnRule = response.data
      commit(RETURN_RULES_SET_RETURN_RULE, returnRule)
    } catch {
      // New users can’t access return rules.
    }
  },

  async [RETURN_RULES_FETCH_RULES]({ commit }: { commit: Commit }) {
    try {
      const response = await ReturnRulesService.fetchReturnRules()
      const returnRules = response.data
      commit(RETURN_RULES_SET_RETURN_RULES, returnRules)
    } catch {
      // New users can’t access return rules.
    }
  },

  async [RETURN_RULES_MOVE_RULES]({ dispatch }: { dispatch: Dispatch }, reorderPayload: { rule_list: number[] }) {
    await ReturnRulesService.reorderReturnRules(reorderPayload)
    dispatch(RETURN_RULES_FETCH_RULES)
  },

  async [RETURN_RULES_TOGGLE_RULE](
    { dispatch }: { dispatch: Dispatch },
    { returnRuleId, patchPayload }: { returnRuleId: number, patchPayload: { is_active: boolean } },
  ) {
    await ReturnRulesService.toggleReturnRule(returnRuleId, patchPayload)
    dispatch(RETURN_RULES_FETCH_RULES)
  },

  async [RETURN_RULES_UPDATE_RULE]({ dispatch }: { dispatch: Dispatch }, returnRule: ReturnRule) {
    await ReturnRulesService.updateReturnRule(returnRule)
    dispatch(RETURN_RULES_FETCH_RULES)
  },
}

const mutations = {
  [RETURN_RULES_SET_RETURN_RULE](state: ReturnRulesState, returnRule: ReturnRule) {
    state.returnRules = [...(state.returnRules || []), returnRule]
  },
  [RETURN_RULES_SET_RETURN_RULES](state: ReturnRulesState, returnRules: ReturnRule[]) {
    state.returnRules = returnRules
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
